<template>
  <div class="app-container">
    <el-drawer title="Search Filter" :visible.sync="drawerFilterVisible" direction="btt" size="50%">
      <div class="drawer">
        <el-form :ref="form" label-width="100px" label-position="left">
          <el-form-item label="Employee">
            <el-select v-model="form.staff" placeholder="Select Employee">
              <el-option v-for="person in personnel" :key="person.em_id" :label="person.em_id_name" :value="person.em_id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Year">
            <el-date-picker v-model="form.year" type="year" placeholder="Pick a year" value-format="yyyy"/>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <el-button @click="closeFilterDialog()">Cancel</el-button>
          <el-button type="primary" @click="confirmFilterDialog()">Confirm</el-button>
        </div>
      </div>
    </el-drawer>
    <el-card>
      <div slot="header" class="card-header">
        <h4 style="margin: 10;">PERSONNEL YEARLY LEAVE HISTORY</h4>
        <el-button type="primary" icon="el-icon-user" @click="drawerFilterVisible = true">Search Filter</el-button>
      </div>
      <div class="controls">
        <div class="control">
          <p>Items per page: </p>
          <el-select v-model="items">
            <el-option :label="5" :value="5"></el-option>
            <el-option :label="10" :value="10"></el-option>
            <el-option :label="25" :value="25"></el-option>
            <el-option :label="50" :value="50"></el-option>
            <el-option :label="100" :value="100"></el-option>
          </el-select>
        </div>
        <div class="control">
            <el-button-group>
              <el-button type="primary" icon="el-icon-arrow-left" @click="prevPage()" :disabled="!hasPreviousPage">Previous Page</el-button>
              <el-button type="primary" @click="nextPage()" :disabled="!hasNextPage">Next Page<i class="el-icon-arrow-right el-icon-right"></i></el-button>
            </el-button-group>
        </div>
      </div>
      <h4 style="margin-top: 0" v-if="employee">EMPLOYEE: {{employee}}</h4>
      <el-collapse accordion class="hidden-md-and-up">
        <el-collapse-item v-for="(file, index) in data" :key="index" :name="file.id">
          <template slot="title">
            <p style="width: 100%">{{ file.bil_id_name }} [{{ formatDate(file.date_filed) }}]</p>
          </template>
          <p>Application Status: <el-tag :type="getStatusType(file.a_id_name)">{{ file.a_id_name }}</el-tag></p>
          <p>Date/s Covered: <b>{{ formatDate(file.leave_date) }}</b></p>
          <p>Duration: <b>{{file.duration_bg4_id_name}}</b></p>
          <p>Reason/s: <b>{{ file.reason }}</b></p>
        </el-collapse-item>
      </el-collapse>
      <el-table :data="data" border class="hidden-sm-and-down">
        <el-table-column type="expand">
          <template slot-scope="props">
            <p class="expanded-p">Reason/s: <b>{{props.row.reason}}</b></p>
          </template>
        </el-table-column>
        <el-table-column label="APPLICATION TYPE" prop="bil_id_name"></el-table-column>
        <el-table-column label="DURATION" prop="duration_bg4_id_name"></el-table-column>
        <el-table-column label="DATE FILED" prop="date_filed">
          <template slot-scope="props">
            <p>{{ formatDate(props.row.date_filed) }}</p>
          </template>
        </el-table-column>
        <el-table-column label="DATE OF LEAVE" prop="leave_date">
          <template slot-scope="props">
            <p>{{ formatDate(props.row.leave_date) }}</p>
          </template>
        </el-table-column>
        <!-- <el-table-column label="REASON" prop="reason"></el-table-column> -->
        <el-table-column label="STATUS" prop="a_id_name">
          <template slot-scope="props">
           <el-tag :type="getStatusType(props.row.a_id_name)">{{ props.row.a_id_name }}</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { format } from 'date-fns'
export default {
  name: 'LeaveHistory',
  data () {
    return {
      windowWidth: window.innerWidth,
      personnel: [],
      employee: null,
      data: [],
      drawerFilterVisible: false,
      items: 5,
      page: 1,
      hasNextPage: false,
      hasPreviousPage: false,
      form: {
        staff: null,
        year: null
      }
    }
  },
  computed: {
    isMobile () {
      return this.windowWidth < 992
    }
  },
  watch: {
    items () {
      this.page = 1
      this.getLeaveHistory()
    }
  },
  mounted () {
    this.getPersonnels()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    getLeaveHistory () {
      this.$http
        .get(`ess.leavehistory/${this.form.staff}/${this.form.year}/${this.items}?page=${this.page}`)
        .then(res => {
          this.employee = this.personnel.find(employee => employee.em_id === this.form.staff).em_id_name
          this.data = res.data.StatusCode ? res.data.body.data : []
          this.hasNextPage = res.data.StatusCode ? !!res.data.body.next_page_url : false
          this.hasPreviousPage = res.data.StatusCode ? !!res.data.body.prev_page_url : false
        })
    },
    getPersonnels () {
      this.$http
        .get(`mss.personnels-list/${this.$store.getters.user.employeeId}`)
        .then(res => {
          this.personnel = res.data.body
        })
    },
    nextPage () {
      this.page++
      this.getLeaveHistory()
    },
    prevPage () {
      this.page--
      this.getLeaveHistory()
    },
    formatDate (date, dateFormat = 'iii, dd MMM yyyy') {
      return format(new Date(date), dateFormat)
    },
    getStatusType (type) {
      if (type === 'Waiting') {
        return 'primary'
      } else if (type === 'Approved') {
        return 'success'
      } else {
        return 'danger'
      }
    },
    confirmFilterDialog () {
      this.drawerFilterVisible = false
      this.getLeaveHistory()
    },
    closeFilterDialog () {
      this.drawerFilterVisible = false
      this.form.staff = null
      this.form.year = null
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.onResize)
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
